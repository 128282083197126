import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e1c7798e = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _e8b1c1de = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _bee72cb4 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3e57c589 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7f4c3294 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _5c03119a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7d31c729 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _787b5956 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _355d9c34 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _b7d7ad18 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _e87ae9e4 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _ea0fbb4e = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _e16642d4 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _172fb10c = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _59f395cc = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _3f2221f5 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _c2319782 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _2921ab35 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _159008ff = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _373c01d0 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _e1c7798e,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _e8b1c1de,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _bee72cb4,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _3e57c589,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _7f4c3294,
    name: "receipt"
  }, {
    path: "/search",
    component: _5c03119a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _7d31c729,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _787b5956,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _355d9c34,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _b7d7ad18,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _e87ae9e4,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _ea0fbb4e,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _e16642d4,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _172fb10c,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _59f395cc,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _3f2221f5,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _c2319782,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _2921ab35,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _159008ff,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _373c01d0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
